import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      height: "100%",
      minHeight: "100vh",
      backgroundColor: "#fff",
    },
    headerWrapper: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      position: "absolute",
      top: 0,
      left: 0,
      marginTop: 16,
      marginLeft: 16,
      zIndex: 1000,
      width: "15%",
    },
    seacrhFieldWrapper: {
      background: "rgba(255, 255, 255, 0.41)",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(15px)",
      borderRadius: 32,
      padding: "9px 16px",
      marginTop: 0,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      width: "90%",
      marginBottom: "14px",
      gap: "16px",
    },
    searchIcon: {
      color: "#FFF",
    },
    searchField: {
      width: "100%",
      color: "#FFF",
      fontSize: 15,
      fontWeight: 700,
    },
    rootWrapper: {
      position: "relative",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px 20px 0 0",
      paddingBottom: 60,
      paddingTop: 16,
    },
    divider: {
      width: "50px",
      height: "4px",
      borderRadius: "10px",
      background: "#F5F5F5",
      marginLeft: "auto",
      marginRight: "auto",
    },
    cardMembershipWrapper: {
      borderRadius: "25px 25px 0 0",
      margin: "18px 16px 0px",
    },
    serviceSection: {
      margin: "20px 16px 0px",
    },
    bannerHeader: {
      padding: "0px 16px",
    },
    listBannerWrapper: {
      display: "flex",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        background: "transparent",
      },
      gap: 8,
      marginTop: 18,
      padding: "0px 16px",
    },
    becomeMemberCard: {
      padding: "10px 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paginationImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 12,
      marginLeft: 10,
      marginTop: -130,
      position: "relative",
    },
    dot: {
      backgroundColor: "#e4e6e7",
      height: 6,
      width: 6,
      borderRadius: 6,
      margin: 3,
    },
    dotActive: {
      backgroundColor: "#FFFFFF",
      height: 6,
      width: 20,
      borderRadius: 6,
      margin: 3,
    },
    rootCard: {
      margin: "auto",
      padding: 0,
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px !important",
    },
    menuWalletVoucherWrapper: {
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      cursor: "pointer",
      padding: "16px !important",
    },
    membershipWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 15px 15px 15px",
    },
    groupWrapper: {
      width: "100%",
      maxWidth: "340px",
    },
    gridService: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomSheetWraper: {
      "& .MuiDrawer-paperAnchorBottom": {
        backgroundColor: "inherit",
        boxShadow: "inherit",
      },
      bottom: 0,
    },
    bottomSheet: {
      padding: "16px 16px 24px 16px",
      backgroundColor: "#FFFFFF",
      borderRadius: "16px 16px 0px 0px",
    },
    bottomSheetDividerTop: {
      width: 76.8,
      height: 4,
      borderRadius: 10,
      backgroundColor: "#F5F5F5",
      marginBottom: 16,
    },
    loginButton: {
      fontWeight: 700,
      marginTop: 34,
      padding: 8,
      color: "white",
      width: "100%",
      boxShadow: "0px 10px 15px rgba(51, 131, 225, 0.2)",
      borderRadius: 5,
    },
    errorWrapper: {
      textAlign: "center",
    },
    errorMessage: {
      fontWeight: 600,
      marginTop: 16,
    },
  })
);

export default useStyles;
