import { axiosInstance } from "../config/axios";
import { ListBannerDto } from "../libs/banner";

export const getBanners = async () => {
  const response: ListBannerDto = await axiosInstance.get(
    "/guest/banners?page=1&per_page=100"
  );
  return response;
};

export const getBannerDetail = async (id: string) => {
  const response = await axiosInstance.get(`/guest/banners/${id}`);
  return response;
};
