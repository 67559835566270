import { Box, Button, Container, Drawer, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import dayjs from "dayjs";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import { Router, withTranslation } from "../i18n";
import { Sorryavatar } from "../public/img";
import BottomNav from "../src/components/BottomNavigation";
import CardMembership from "../src/components/CardMembership";
import ListBanner from "../src/components/ListBannerHomepage";
import ServiceSectionHomepage from "../src/components/ServiceSectionHomepage";
import SkeletonHome from "../src/components/Skeleton/Home";
import SwipeableImageHome from "../src/components/SwipeableImageHome";
import { BannerDto } from "../src/libs/banner";
import { GuestMembershipByGroupIdDto } from "../src/libs/membership";
import { TenantProfileDto } from "../src/libs/tenant";
import { getBanners } from "../src/services/banner";
import { getTenantProfile } from "../src/services/common";
import { getGuestMembershipByGroupId } from "../src/services/membership";
import { getTotalPoints } from "../src/services/points";
import { getGuestVoucher } from "../src/services/voucher";
import { getGuestTotalBalances } from "../src/services/wallet";
import useStyles from "../styles/home";

type Props = {
  readonly t: TFunction;
};

function Home(props: Props) {
  const classes = useStyles(props);
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [points, setPoints] = useState<number>(0);
  const [wallet, setWallet] = useState<number>(0);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [loginDate, setLoginDate] = useState("");
  const [totalVoucher, setTotalVoucher] = useState<number>(0);
  const [membershipData, setMembershipData] =
    useState<GuestMembershipByGroupIdDto["data"]>();
  const [tenantProfile, setTenantProfile] = useState<TenantProfileDto>(Object);
  const [listBanner, setListBanner] = useState<BannerDto[]>([]);
  const [error, setError] = useState(Object);

  const today = dayjs(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginDate = localStorage.getItem("loginDate");
    if (token) {
      setIsLogin(true);
      setLoginDate(loginDate as string);
    } else {
      setIsLogin(false);
    }
  }, []);

  const getConfigTenant = async () => {
    try {
      const response = await getTenantProfile();
      setTenantProfile(response.data);
    } catch (error) {
      setError(error?.response?.data?.meta);
    }
  };

  const getListBanner = async () => {
    if (isLogin) {
      setLoading(true);
    }
    try {
      const response = await getBanners();
      setListBanner(response.data);
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data?.meta);
      setLoading(false);
    }
  };

  const fetchDataMembership = async () => {
    setLoading(true);
    const dataLogin = JSON.parse(localStorage.getItem("data_login") || "{}");
    try {
      if (tenantProfile?.pointConfiguration?.isEnabled) {
        const point = await getTotalPoints();
        setPoints(point.data.totalPoints);
      }
      if (tenantProfile?.walletConfig?.isEnabled) {
        const balance = await getGuestTotalBalances();
        setWallet(balance.data.total_balances);
      }
      if (tenantProfile?.membershipConfig?.isEnabled) {
        const membership = await getGuestMembershipByGroupId(
          dataLogin.guest?.memberships?.[0]
        );
        setMembershipData(membership.data);
      }
      const voucher = await getGuestVoucher({
        page: "1",
        per_page: "10",
      });
      setTotalVoucher(voucher.meta.total_data);
      setLoading(false);
    } catch (error) {
      setError(error.response.data.meta);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListBanner();
  }, []);

  useEffect(() => {
    if (isLogin && loginDate && dayjs(today).diff(loginDate, "days") < 2) {
      getConfigTenant();
    }
  }, [isLogin, loginDate]);

  useEffect(() => {
    if (isLogin && loginDate && dayjs(today).diff(loginDate, "days") < 2) {
      fetchDataMembership();
    }
  }, [tenantProfile]);

  return (
    <>
      {loading ? (
        <SkeletonHome isLogin={isLogin} t={t} />
      ) : (
        <>
          <Container maxWidth="xs" className={classes.root}>
            <Box className={classes.headerWrapper}>
              <img
                src={
                  process.env.NEXT_PUBLIC_WHITE_LOGO ||
                  "https://swaps-bucket.s3.ap-southeast-1.amazonaws.com/Group%2024.svg-2022-08-31T05%3A40%3A41.665Z"
                }
                className={classes.logo}
              />
              <SwipeableImageHome />
              <Box
                className={classes.seacrhFieldWrapper}
                onClick={() => Router.push("/hotels/rooms/search-location")}
              >
                <SearchIcon className={classes.searchIcon} />
                <Typography variant="body2" className={classes.searchField}>
                  {t("find-hotel-location")}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.rootWrapper}>
              <Box className={classes.cardMembershipWrapper}>
                <CardMembership
                  wallet={wallet}
                  points={points}
                  totalVoucher={totalVoucher}
                  membershipData={membershipData}
                  isLogin={isLogin}
                  tenantProfile={tenantProfile}
                  t={t}
                />
              </Box>
              <ServiceSectionHomepage t={t} />
              {listBanner?.length > 0 && (
                <ListBanner t={t} listBanner={listBanner} />
              )}
            </Box>
            <BottomNav t={t} />
            <Drawer
              anchor="bottom"
              open={error?.status_code}
              className={classes.bottomSheetWraper}
              onClose={() => {
                error?.status_code !== 401 && setError(null);
              }}
              disableScrollLock={true}
            >
              <Container maxWidth="xs">
                <Box className={classes.bottomSheet}>
                  <Box className={classes.errorWrapper}>
                    <Sorryavatar />
                    <Typography className={classes.errorMessage}>
                      {error?.message}
                    </Typography>
                  </Box>
                  {error?.status_code === 401 && (
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.loginButton}
                      onClick={() => Router.push(`/login?redirect=/profile`)}
                    >
                      {t("login")}
                    </Button>
                  )}
                </Box>
              </Container>
            </Drawer>
          </Container>
        </>
      )}
    </>
  );
}

Home.getInitialProps = async () => ({
  namespacesRequired: ["common"],
});

export default withTranslation("common")(Home);
