import { Typography } from "@material-ui/core";
import { TFunction } from "next-i18next";
import React from "react";
import { Router } from "../../i18n";
import useStyles from "../../styles/components/ListBannerHomepage";
import { BannerDto } from "../libs/banner";

type ListBannerProps = {
  readonly t: TFunction;
  listBanner: BannerDto[];
};

const ListBanner = (props: ListBannerProps) => {
  const classes = useStyles();
  const { t, listBanner } = props;

  return (
    <div className={classes.bannerSection}>
      <div className={classes.bannerHeader}>
        <Typography className={classes.titleBannerSection}>
          {t("attractive-promo-for-you")}
        </Typography>
        <Typography className={classes.subtitleBannerSection}>
          {t("many-package-options-for-you")}
        </Typography>
      </div>
      <div className={classes.listBannerWrapper}>
        {listBanner.map((res, index) => (
          <div key={index}>
            <div onClick={() => Router.push(`/detail-banner?id=${res.id}`)}>
              <img
                src={res.image.url}
                width={180}
                height={120}
                className={classes.banner}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListBanner;
