import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import useStyles from "../../styles/home";

export default function SwipeableImageHome() {
  const [indexImg, setIndexImg] = useState(0);
  const classes = useStyles();

  const listImage = [
    {
      url: `/img/JatengRawaPening.webp`,
    },
    {
      url: `/img/JabarCiwidey.webp`,
    },
    {
      url: `/img/JatengBorobudur.webp`,
    },
    {
      url: `/img/JabarTangkubanPerahu.webp`,
    },
    {
      url: `/img/Yogyakarta.webp`,
    },
  ];

  return (
    <div>
      <AutoPlaySwipeableViews
        enableMouseEvents
        index={indexImg}
        onChangeIndex={(index) => setIndexImg(index)}
      >
        {listImage.map((item, index) => (
          <div key={index}>
            <img
              style={{ backgroundColor: "lightgrey" }}
              src={item.url}
              alt="image-swipeable"
              height="100%"
              width="100%"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div className={classes.paginationImg}>
        {listImage.map((item, index) => (
          <div
            key={index}
            className={index === indexImg ? classes.dotActive : classes.dot}
          />
        ))}
      </div>
    </div>
  );
}
