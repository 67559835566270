import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    serviceSection: {
      margin: "20px 16px 0px",
    },
    serviceTitle: {
      fontWeight: 700,
    },
    buttonService: {
      display: "block !important",
      borderRadius: 10,
      padding: 0,
    },
    iconService: {
      marginBottom: -20,
    },
    service: {
      fontSize: "12px",
      color: "#52575C",
    },
  })
);

export default useStyles;
