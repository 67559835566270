import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    bannerSection: {
      marginTop: 16,
    },
    bannerHeader: {
      padding: "0px 16px",
    },
    titleBannerSection: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitleBannerSection: {
      color: "#25282BBF",
      fontSize: 14,
    },
    listBannerWrapper: {
      display: "flex",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        background: "transparent",
      },
      gap: 8,
      marginTop: 18,
      padding: "0px 16px",
    },
    banner: {
      borderRadius: 5,
      objectFit: "cover",
      cursor: "pointer",
      backgroundColor: "lightgrey",
    },
  })
);

export default useStyles;
