import { Card, CardContent, Container, Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Skeleton from "@material-ui/lab/Skeleton";
import { TFunction } from "next-i18next";
import React from "react";
import useStyles from "../../../styles/home";
import BottomNav from "../BottomNavigation";

type SkeletonProps = {
  isLogin?: boolean;
  readonly t: TFunction;
};

const SkeletonHome = (props: SkeletonProps) => {
  const classes = useStyles(props);
  const { isLogin, t } = props;

  return (
    <>
      <Container maxWidth="xs" className={classes.root}>
        <div className={classes.headerWrapper}>
          <Skeleton variant="rect" width="100%" height={444} />
        </div>

        <div className={classes.rootWrapper} style={{ marginTop: "-45px" }}>
          <div className={classes.divider}></div>
          <div style={{ padding: "18px 16px 22px 16px" }}>
            {isLogin ? (
              <Card className={classes.rootCard}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CardContent className={classes.menuWalletVoucherWrapper}>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between",
                      }}
                    >
                      <Skeleton
                        style={{ marginRight: "10px" }}
                        variant="rect"
                        height={13}
                        width={18}
                      />
                      <div>
                        <Skeleton variant="text" height={18} width={65} />
                        <Skeleton variant="text" height={18} width={65} />
                      </div>
                    </div>
                  </CardContent>
                  <CardContent className={classes.menuWalletVoucherWrapper}>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <Skeleton
                          style={{ marginRight: "10px" }}
                          variant="rect"
                          height={13}
                          width={18}
                        />
                      </div>
                      <div>
                        <Skeleton variant="text" height={18} width={65} />
                        <Skeleton variant="text" height={18} width={65} />
                      </div>
                    </div>
                  </CardContent>
                </div>
                <div className={classes.membershipWrapper}>
                  <div className={classes.groupWrapper}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "4px",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="text" height={21} width={50} />
                      <Skeleton
                        variant="text"
                        height={14}
                        width={160}
                        style={{ marginLeft: "6px" }}
                      />
                    </div>
                    <Skeleton variant="text" height={10} width={340} />
                  </div>
                  <ChevronRightIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Card>
            ) : (
              <Card
                style={{
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
              >
                <CardContent
                  className={classes.becomeMemberCard}
                  style={{ padding: "10px 16px" }}
                >
                  <div>
                    <Skeleton variant="text" height={21} width={120} />
                    <Skeleton variant="text" height={20} width={142} />
                  </div>

                  <ChevronRightIcon color="primary" />
                </CardContent>
              </Card>
            )}
          </div>

          <div className={classes.serviceSection} style={{ paddingTop: 1 }}>
            <Skeleton variant="text" width={159} height={21} />
            <Grid container style={{ marginTop: 24 }}>
              {[1, 2, 3].map((res, index) => (
                <Grid key={index} item xs={4} className={classes.gridService}>
                  <div>
                    <Skeleton variant="circle" width={35} height={35} />
                    <Skeleton
                      variant="text"
                      width={38}
                      height={23}
                      style={{ margin: "auto" }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className={classes.bannerHeader}>
            <Skeleton variant="text" width={208} height={27} />
            <Skeleton variant="text" width={180} height={23} />
          </div>
          <div className={classes.listBannerWrapper}>
            {[1, 2, 3].map((res, index) => (
              <div key={index}>
                <Skeleton
                  variant="rect"
                  width={180}
                  height={120}
                  style={{ borderRadius: "10px" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <BottomNav t={t} />
        </div>
      </Container>
    </>
  );
};

export default SkeletonHome;
