import { Button, Grid, Typography } from "@material-ui/core";
import { TFunction } from "next-i18next";
import React from "react";
import { Router } from "../../i18n";
import useStyles from "../../styles/components/ServiceSectionHomepage";

type ServiceSectionHomepageProps = {
  readonly t: TFunction;
};

const ServiceSectionHomepage = (props: ServiceSectionHomepageProps) => {
  const classes = useStyles();
  const { t } = props;

  return (
    <div className={classes.serviceSection}>
      <Typography className={classes.serviceTitle}>
        {t("what-do-yo-want-today")}
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Button
            className={classes.buttonService}
            fullWidth
            onClick={() => Router.push("/hotels/rooms/search-location")}
          >
            <img src="/img/room.svg" className={classes.iconService} />
            <Typography className={classes.service}>Rooms</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className={classes.buttonService}
            fullWidth
            onClick={() => Router.push("/hotels/foods/search-location")}
          >
            <img src="/img/food.svg" className={classes.iconService} />
            <Typography className={classes.service}>Foods</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className={classes.buttonService}
            fullWidth
            onClick={() => Router.push("/hotels/souvenirs/search-location")}
          >
            <img src="/img/gift.svg" className={classes.iconService} />
            <Typography className={classes.service}>Souvenirs</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceSectionHomepage;
